var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profile-page"},[_c('el-row',{attrs:{"gutter":30}},[_c('el-col',{attrs:{"lg":6,"md":8,"sm":24}},[_c('div',{staticClass:"profile-card text-center",staticStyle:{"max-width":"400px","margin-bottom":"20px"},attrs:{"shadow":"never"}},[_c('el-button',{staticClass:"my-3",attrs:{"type":"text"}},[_c('el-tooltip',{attrs:{"content":_vm.getUserProfilePicture && _vm.getUserProfilePicture.length > 20 ? 'Update Profile Picture' : 'Add Profile Picture'}},[(_vm.getUserProfilePicture && _vm.getUserProfilePicture.length > 20
                  )?_c('img',{staticClass:"inline-block",staticStyle:{"border-radius":"50%"},attrs:{"src":_vm.getUserProfilePicture,"alt":"Avatar","width":"120"},on:{"click":function($event){_vm.outerVisible = true}}}):_c('img',{staticClass:"inline-block",staticStyle:{"border-radius":"50%"},attrs:{"src":require("@/assets/img/avatar.svg"),"alt":"Avatar","width":"120"},on:{"click":function($event){_vm.outerVisible = true}}})]),(_vm.getUserProfilePicture && _vm.getUserProfilePicture.length > 20)?_c('span',{staticStyle:{"margin-top":"20px","margin-left":"-45px"}},[_c('el-button',{staticStyle:{"background-color":"ivory"},attrs:{"type":"plain","size":"mini","circle":""},on:{"click":function($event){return _vm.deleteProfile()}}},[_c('img',{attrs:{"width":"12px","src":require("@/assets/Trash.svg")}})])],1):_vm._e()],1),_c('hr'),_c('div',{staticClass:"list-group text-left"},[_c('span',{class:_vm.getClass('personal-information'),on:{"click":function($event){return _vm.goTo('personal-information')}}},[_c('i',{staticClass:"el-icon-user"}),_vm._v(" Personal Information")]),_c('span',{class:_vm.getClass('change-password'),on:{"click":function($event){return _vm.goTo('change-password')}}},[_c('i',{staticClass:"el-icon-lock"}),_vm._v(" Change Password")]),_c('span',{class:_vm.getClass('signature'),on:{"click":function($event){return _vm.goTo('signature')}}},[_c('i',{staticClass:"el-icon-edit"}),_vm._v(" Signature")]),((_vm.getActiveWorkspace.company_id &&
            _vm.getUserType && this.isOwner()) || this.isIndividualWorkspace(this.getActiveWorkspace)
            )?_c('span',{class:_vm.getClass('subscription'),on:{"click":function($event){return _vm.goTo('subscription')}}},[_c('i',{staticClass:"el-icon-box"}),_vm._v(" Subscription")]):_vm._e(),(_vm.getActiveWorkspace.company_id &&
            _vm.getUserType &&
            (this.isAdmin() || this.isOwner()) && !this.isIndividualWorkspace(this.getActiveWorkspace)
            )?_c('span',{class:_vm.getClass('companyProfile'),on:{"click":function($event){return _vm.goTo('companyProfile')}}},[_c('i',{staticClass:"el-icon-suitcase"}),_vm._v(" Company Profile")]):_vm._e(),(_vm.getActiveWorkspace &&
            _vm.getActiveWorkspace.company_id &&
            _vm.getUserType && !this.isIndividualWorkspace(this.getActiveWorkspace)&&
            (_vm.getUserType.name && this.isOwner() && this.isWorkspaceIsBusiness(_vm.getActiveWorkspace) || _vm.checkIsSuperAdminOrNotForProfileManagementPermissions('managePermissions'))
            )?_c('span',{class:_vm.getClass('manage-permissions'),on:{"click":function($event){return _vm.goTo('manage-permissions')}}},[_c('i',{staticClass:"el-icon-key"}),_vm._v(" Manage Permissions")]):_vm._e(),(_vm.getActiveWorkspace &&
            _vm.getActiveWorkspace.company_id &&
            _vm.getUserType && !this.isIndividualWorkspace(this.getActiveWorkspace) &&
            (_vm.getUserType.name && this.isOwner() && this.isWorkspaceIsBusiness(_vm.getActiveWorkspace) || _vm.checkIsSuperAdminOrNotForProfileManagementPermissions('menuManagement'))
            )?_c('span',{class:_vm.getClass('menuManagement'),on:{"click":function($event){return _vm.goTo('/menu-management', true)}}},[_c('i',{staticClass:"el-icon-more-outline"}),_vm._v(" Menu Management ")]):_vm._e(),(_vm.getActiveWorkspace &&
            _vm.getActiveWorkspace.company_id &&
            _vm.getUserType && !this.isIndividualWorkspace(this.getActiveWorkspace) &&
            (_vm.getUserType.name && this.isOwner() && this.isWorkspaceIsBusiness(_vm.getActiveWorkspace) ||  _vm.checkIsSuperAdminOrNotForProfileManagementPermissions('roleManagement'))
            )?_c('span',{class:_vm.getClass('menuManagement'),on:{"click":function($event){return _vm.goTo('/roles', true)}}},[_c('i',{staticClass:"el-icon-user"}),_vm._v(" Role Management ")]):_vm._e()])],1)]),_c('el-col',{attrs:{"lg":18,"md":16,"sm":24}},[_c(_vm.layout,{tag:"component"},[_c('router-view',{attrs:{"layout":_vm.layout},on:{"update:layout":function($event){_vm.layout=$event}}})],1)],1)],1),_c('dialog-component',{attrs:{"width":_vm.getIsMobile ? '100%' : '100%',"height":_vm.getIsMobile ? '100%' : '100%',"title":'Update Profile Picture',"visible":_vm.outerVisible},on:{"before-close":_vm.closeDialog}},[_c('vue-anka-cropper',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.upLoadEffect),expression:"upLoadEffect"}],staticClass:"upload-file",attrs:{"options":{
                aspectRatio: false,
                closeOnSave: true,
                cropArea: 'circle',
                croppedHeight: 1000,
                croppedWidth: 1000,
                cropperHeight: false,
                dropareaMessage:
                  ' Drag & Drop  file here or use the button below.',
                frameLineDash: [1, 1000],
                frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
                handleFillColor: 'rgba(255, 255, 255, 0.2)',
                handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
                handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
                handleSize: 5,
                handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
                layoutBreakpoint: 40,
                maxCropperHeight: 1000,
                maxFileSize: 8000000,
                overlayFill: 'rgba(0, 0, 0, 0.5)',
                previewOnDrag: true,
                previewQuality: 0.65,
                resultQuality: 0.8,
                resultMimeType: 'image/jpeg',
                resultMimeType: 'image/png',
                resultMimeType: 'image/jpg',
                resultMimeType: 'image/gif',
                selectButtonLabel:
                  'Select File Supported : JPG, PNG, JPEG, GIF',

                showPreview: true,
                skin: 'light',
                uploadData: {},
                uploadTo: false,
              },"name":"logo","accept":"image/jpeg,image/png,image/jpg,image/gif"},on:{"cropper-error":_vm.fileError,"cropper-preview":_vm.uploadFile,"cropper-file-selected":_vm.uploadFile,"cropper-saved":_vm.onUpdate}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.outerVisible = false;
                _vm.resetImgData();}}},[_vm._v("Cancel")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.onUpdate()}}},[_vm._v("Save")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }